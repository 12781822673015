export const readMore = (): void => {
  const buttons = Array.from(document.querySelectorAll('.js-read-more-button'))
  if (buttons.length === 0) {
    return
  }

  buttons.forEach((button) => {
    button.addEventListener('click', (event) => {
      const b = event.target as HTMLElement
      const wrapper = b.closest('.js-read-more')
      const content = wrapper?.querySelector('.js-read-more-content')
      content?.classList.add('read-more__content_visible')
      b?.classList.add('read-more__button_hidden')
    })
  })
}
