export const cardsFilter = (): void => {
  const cardSelectFilters = document.querySelectorAll('.js-card-select-filter')

  // TODO: polyfill?
  const cardSelectFiltersArray = Array.from(cardSelectFilters) as HTMLSelectElement[]

  if (!cardSelectFiltersArray) {
    return
  }

  cardSelectFiltersArray.forEach((filter) => {
    filter.addEventListener('change', function () {
      const otherFilters = cardSelectFiltersArray.filter((el) => el !== filter)
      resetSelects(otherFilters)

      const classHidden = 'cards__item_hidden'
      const tagId = filter.value
      const tagsShouldBeFiltered = tagId !== ''

      // TODO: polyfill?
      const cards = Array.from(document.querySelectorAll('.js-cards-item'))

      cards.forEach((program) => program.classList.remove(classHidden))

      if (tagsShouldBeFiltered) {
        cards.forEach((program) => {
          if (!program.getAttribute('data-card-filter-ids')?.includes(tagId)) {
            program.classList.add(classHidden)
          }
        })
      }
    })
  })
}

function resetSelects(filters: HTMLSelectElement[]) {
  filters.forEach((el) => (el.value = ''))
}
