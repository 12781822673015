import '../styles/main.css'

// pollyfills

import 'intersection-observer'
import 'core-js/es/array/from'

// libs

// Gives information as to what input method a user is using. Helps with custom
// outline styles.
import 'what-input'
import 'lazysizes'

// components

import { bigLogo } from './dialog/bigLogo'
import { cardsFilter } from './cardsFilter'
import { navToggle } from './header/navToggle'
import { mediaSlider } from './mediaSlider'
import { readMore } from './read-more'

bigLogo()
cardsFilter()
navToggle()
mediaSlider()
readMore()
