export const idA11yDialogMainContent = 'a11y-dialog-main'

export const forceMuteIframe = (parentElement: HTMLElement): void => {
  const iframe = parentElement.querySelector('iframe')

  if (!iframe) {
    return
  }

  // `data-src` because of lazy loading with lazysizes
  const src = iframe.getAttribute('data-src')

  if (!src) {
    return
  }

  iframe.setAttribute('src', src)
}
