import A11yDialog from 'a11y-dialog'
import { idA11yDialogMainContent, forceMuteIframe } from '../utils'

export const bigLogo = (): void => {
  const dialogElement = document.getElementById('dialog-big-logo')

  if (!dialogElement) {
    return
  }

  const dialogA11y = new A11yDialog(dialogElement, idA11yDialogMainContent)

  dialogA11y.on('hide', function (dialogEl) {
    forceMuteIframe(dialogEl as HTMLElement)
  })
}
