import Glide from '@glidejs/glide'
import { forceMuteIframe } from './utils'

export const mediaSlider = (): void => {
  const sliderSelector = '.js-glide'
  const slider = document.querySelector(sliderSelector)

  if (!slider) {
    return
  }

  // TODO: polyfill?
  const slides = Array.from(slider.querySelectorAll('.js-glide-slide'))

  if (slides.length === 1) {
    return
  }

  const options: Glide.Options = {
    type: slides.length > 2 ? 'carousel' : 'slider',
  }

  const glide = new Glide(sliderSelector, options)

  glide.on('run', function () {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore glide TS interface not really up-to-date…
    const index = glide.index === 0 ? slides.length - 1 : glide.index - 1
    forceMuteIframe(slides[index] as HTMLElement)
  })

  // sometimes iframes cause the slider to have a weird height on page load. setTimout should fix this...
  setTimeout(() => {
    glide.mount()
  }, 0)
}
